















































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../assets/css/global.css';
@import "../../assets/css/antd.css";
.control{
	height: 35px;
	padding: 5px 10px;
	font-family: PingFangSC-Regular;
	font-size: 13px;
	color: #E2E2E2;
	font-weight: 400;
}
.chain_station{
	padding-bottom: 10px;
	width: 170px;
	background: #30303C;
	border-radius: 16px;
	margin: auto;
}
.chain_title{
	float: left;
	height: 30px;
	padding: 10px;
	font-family: PingFangSC-Semibold;
	font-size: 16px;
	color: #E2E2E2;
	line-height: 27px;
	font-weight: 600;
}
.chain_img{
	padding: 0 26px;
}
.leaf_img{
	padding: 0 49px;
}
.chain_link{
	width: 170px;
	background: #30303C;
	border-radius: 16px;
	margin: auto;
}
.td_name{
	width: 42px;
	height: 18px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 18px;
	font-weight: 400;
}
.td_data{
	width: 44px;
	height: 18px;
	font-family: PingFangSC-Semibold;
	font-size: 14px;
	color: #E2E2E2;
	line-height: 18px;
	font-weight: 600;
}

#leafs {
	overflow-y: hidden;
	overflow-x: auto;
}

#eLinks {
	overflow-y: hidden;
	overflow-x: auto;
}

#roor {
	margin: 0 10px;
	float: left;
}

.root-node {
	background: #CCCCCC;
	cursor: pointer;
	width: 100px;
	padding: 10px 0;
	border: solid #E05E00 2px;
	margin: auto;
}

.leaf {
	background: #CCCCCC;
	cursor: pointer;
	width: 100px;
	padding: 10px 0;
	border: solid #E05E00 2px;
	margin: auto;
}

.node {
	width: 150px;
	height: 210px;
	background: #30303C;
	border-radius: 16px;
	margin: auto;
}

.vertical-line-up {
	width: 2px;
	background: #FF0000;
	height: 20px;
	margin: auto;
}

.vertical-line-down {
	width: 2px;
	background: #FF0000;
	height: 20px;
	margin: auto;
}

.chain_childrens {
	overflow: visible;
}

.float-left {
	float: left;
	margin: 0 10px;
}

.horizontal-line {
	overflow: hidden;
	border: solid #FF0000 1px;
	margin: 0 94px;
}
.elink-data{
	text-align: center;
	float: left;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #E2E2E2;
	line-height: 32px;
	font-weight: 400;
}
.elink_data_name{
	padding-left: 16px;
	float: left;
	width: 120px;
	height: 32px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #B7B8BC;
	line-height: 32px;
	font-weight: 400;
}
